<template>
  <vx-card class="mt-base">
    <vs-checkbox v-model="isLegal">صدور برای شخصیت حقیقی</vs-checkbox>
    <div class="flex">
      <vs-input
        type="number"
        v-model="tax"
        label="مالیات"
        class="my-3"
        lable="tax"
      ></vs-input>

      <vs-textarea
        class="my-8"
        style="direction:rtl"
        v-model="description"
        label="توضیحات"
        height="80px"
      />
    </div>
    <div></div>
    <repeater class="" v-model="fields"> </repeater>
    <div class="my-3">
      <vs-checkbox v-model="checkBox">
        پس از پرداخت به حساب پنل کاربر افزوده شود</vs-checkbox
      >
    </div>
    <vs-button @click="addManuel"> ثبت </vs-button>
  </vx-card>
</template>

<script>
import Repeater from "vue-repeater";
import Address from "./Address";
// import "vue-repeater/dist/lib/vue-repeater.css";
export default {
  components: {
    repeater: Repeater,
    address: Address
  },
  props: {
    value: {
      type: Object
    }
  },

  data() {
    return {
      isLegal: "",
      description: "",
      userId: this.$route.id,
      tax: 9,
      fields: [
        {
          name: Address,
          value: {}
        }
      ],
      checkBox: false
    };
  },
  computed: {
    filedsObj() {
      const d = this.fields.map(el => el.value);
      return d;
    },
    LegalCheck() {
      return this.isLegal ? 0 : 1;
    }
  },
  watch: {
    isLegal() {
      console.log(this.isLegal);
    }
  },
  methods: {
    addManuel() {
      const data = {
        user_id: this.userId,
        tax: this.tax,
        pay_details_json: JSON.stringify(this.filedsObj),
        is_deposit: this.checkBox ? 1 : 0,
        description: this.description,
        is_legal: this.LegalCheck
      };
      console.log(data);
      this.$http
        .post("/admin/invoice/manual/invoice-issuance", data)
        .then(result => {
          this.$vs.notify({ text: result.data.message });
        })
        .catch(err => {
          console.log(err);
        });
    },
    logger() {
      console.log(this.fields);
    }
  },
  created() {
    this.userId = this.$route.query.id;
    // console.log(this.$route.query)
  }
};
</script>

<style scoped lang="scss">
.repeater-block[data-v-25650116] {
  padding: 16px;
  background: var(--white, var(--def-white));
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 100px;
  border-left: 3px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.repeater-block.active[data-v-25650116] {
  border-color: var(--border-base, var(--def-border-base));
}
.repeater-block.active .navigation[data-v-25650116] {
  display: block;
}
.repeater-block.active .toolbar[data-v-25650116] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.repeater-block .toolbar[data-v-25650116] {
  position: absolute;
  top: -36px;
  left: -3px;
  display: none;
  padding: 0;
  background: var(--white, var(--def-white));
  overflow: hidden;
  border-left: 3px solid var(--border-light, var(--def-border-light));
  border-right: 1px solid var(--border-light, var(--def-border-light));
  border-top: 1px solid var(--border-light, var(--def-border-light));
  border-bottom: 1px solid var(--border-light, var(--def-border-light));
}
.repeater-block .toolbar .block-icon[data-v-25650116],
.repeater-block .toolbar[data-v-25650116] {
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.repeater-block .toolbar .block-icon[data-v-25650116] {
  background: var(--background-base, var(--def-background-base));
  width: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.repeater-block .toolbar .block-icon button[data-v-25650116] {
  cursor: default;
}
.repeater-block .toolbar .block-icon button[data-v-25650116]:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
}
.repeater-block .toolbar button[data-v-25650116] {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 4px;
}
.repeater-block .toolbar button[data-v-25650116]:hover {
  border: 1px solid var(--border-base, var(--def-border-base));
  -webkit-box-shadow: 0 1px 1px var(--border-base, var(--def-border-base));
  box-shadow: 0 1px 1px var(--border-base, var(--def-border-base));
}
.repeater-block[data-v-25650116]:hover {
  border-color: var(--border-base, var(--def-border-base));
}
.repeater-block:hover .navigation[data-v-25650116] {
  display: block;
}
.repeater-block:hover .toolbar[data-v-25650116] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.repeater-block .navigation[data-v-25650116] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -29px;
  width: 29px;
  display: none;
}
.repeater-block .navigation button[data-v-25650116] {
  background: transparent;
  position: absolute;
  left: -2px;
  opacity: 0.5;
  margin-right: 2px;
}
.repeater-block .navigation button[data-v-25650116]:hover {
  opacity: 1;
}
.repeater-block .navigation .nav-anchor[data-v-25650116] {
  border: none;
  padding: 2px 5px;
  outline: none;
  cursor: pointer;
  top: 27px;
}
.repeater-block .navigation .nav-button[data-v-25650116] {
  border: none;
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 4px;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.repeater-block .navigation .nav-button[data-v-25650116]:hover {
  -webkit-box-shadow: inset 0 0 0 1px
      var(--border-light, var(--def-border-light)),
    inset 0 0 0 2px var(--white, var(--def-white)),
    0 1px 1px var(--border-base, var(--def-border-base));
  box-shadow: inset 0 0 0 1px var(--border-light, var(--def-border-light)),
    inset 0 0 0 2px var(--white, var(--def-white)),
    0 1px 1px var(--border-base, var(--def-border-base));
}
.repeater-block .navigation .nav-button.up[data-v-25650116] {
  top: 0;
}
.repeater-block .navigation .nav-button.down[data-v-25650116] {
  top: 51px;
}
.entered[data-v-423cbaa4] {
  -webkit-box-shadow: inset 0 3px 0 0 var(--primary, var(--def-primary));
  box-shadow: inset 0 3px 0 0 var(--primary, var(--def-primary));
}
.repeater-drop-zone[data-v-423cbaa4] {
  padding-top: 8px;
}
:root {
  --def-primary: #409eff;
  --def-success: #67c23a;
  --def-warning: #e6a23c;
  --def-danger: #f56c6c;
  --def-info: #909399;
  --def-text-primary: #303133;
  --def-text-regular: #555d66;
  --def-text-secondary: #909399;
  --def-text-placeholder: #c0c4cc;
  --def-border-base: #dcdfe6;
  --def-border-light: #e4e7ed;
  --def-border-extra-light: #f2f6fc;
  --def-white: #fff;
  --def-black: #000;
  --def-background-base: #f5f7fa;
}
</style>
